import { Component } from '@angular/core';
import { IProductCategoryDescriptions, ProductCategories } from '../data/products';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss']
})
export class PageMenuComponent {
  public productCategories: IProductCategoryDescriptions[] = ProductCategories;
  public showProductCategories: boolean = false;

  toggleProductCategories(): void {
    this.showProductCategories = !this.showProductCategories;
  }
}
