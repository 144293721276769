<h1>Voorwaarden</h1>
<ul>
  <li>De huur en borg dienen contant betaald te worden.</li>
  <li>
    De spullen worden voor 24 uur gehuurd.
    <ul>
      <li>Voorbeeld: Maandagmiddag om 16u komt u de spullen ophalen dan verwachten wij dat de spullen dinsdagmiddag voor 16u weer terug zijn.</li>
    </ul>
  </li>
  <li>De gehuurde spullen moeten netjes en schoon terug gebracht worden.</li>
  <li>Is er schade, meld dit dan meteen. Dan kunnen we dit samen netjes oplossen.</li>
  <li>Ook bij onredelijke vuile spullen worden kosten in rekening gebracht.</li>
  <li>Pas op met kaarsvet; op de tafelrokken veroorzaakt dit moeilijke vlekken en/of brandgaten.</li>
  <li>Ook bij defect product of problemen, gewoon <a href="/contact" routerLink>contact</a> opnemen. Wij staan voor u klaar!</li>
</ul>
