<h1>Bezorgen</h1>
<p>In overleg kunnen wij de gehuurde spullen bezorgen en/of ophalen.</p>
<p>De kosten hiervoor bedragen:</p>
<table>
  <tr>
    <td>Start bedrag</td>
    <td>&euro; 20,00</td>
  </tr>
  <tr>
    <td>Per kilometer</td>
    <td>&euro; 1,50</td>
  </tr>
</table>
<p>Graag contant afrekenen bij aflevering</p>

