<nav id="menu">
  <ul>
    <li *ngIf="false">
      <span class="opener">Producten</span>
      <ul>
        <li *ngFor="let category of productCategories">
          <a [routerLink]="'product/' + category.category" routerLinkActive="active">{{ category.description }}</a></li>
      </ul>
    </li>

    <li><a routerLink="prijzen" routerLinkActive="active">Producten en prijzen</a></li>
    <li><a routerLink="bezorgen" routerLinkActive="active">Bezorgen</a></li>
    <li><a routerLink="voorwaarden" routerLinkActive="active">Voorwaarden</a></li>
    <li><a routerLink="contact" routerLinkActive="active">Contact</a></li>
  </ul>
</nav>
