import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProduct, IProductCategoryDescriptions, ProductCategories, Products } from '../data/products';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  public productCategory: IProductCategoryDescriptions;
  public products: IProduct[];

  routeParamsSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.routeParamsSubscription = this.activatedRoute.params.subscribe(params => {
      const { category } = params;

      this.productCategory = ProductCategories.find(productCategory => productCategory.category === category);

      if (!this.productCategory) {
        this.router.navigate(['']);
      }

      this.products = Products.filter(product => product.category === category);
    })
  }

  ngOnDestroy() {
    this.routeParamsSubscription.unsubscribe();
  }

}
