import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { TermsComponent } from './terms/terms.component';
import { PricesComponent } from './prices/prices.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
  {
    path :'',
    component: IndexComponent,
  },
  {
    path: 'bezorgen',
    component: DeliveryComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'product/:category',
    component: ProductComponent,
  },
  {
    path: 'prijzen',
    component: PricesComponent,
  },
  {
    path: 'voorwaarden',
    component: TermsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
