export type ProductCategory = 'fotografie' | 'muziek' | 'party-tent' | 'sta-tafel'

export interface IProduct {
  category: ProductCategory
  title: string;
  description: string;
  price: string;
}

export interface IProductCategoryDescriptions {
  category: ProductCategory
  description: string;
}

export const ProductCategories: IProductCategoryDescriptions[] = [
  {
    category: 'fotografie',
    description: 'Fotografie'
  },
  {
    category: 'muziek',
    description: 'Muziek'
  },
  {
    category: 'party-tent',
    description: 'Party Tent'
  },
  {
    category: 'sta-tafel',
    description: 'Sta Tafel'
  }
];

export const Products: IProduct[] = [
  {
    category: 'fotografie',
    title: 'Samsung Note',
    description: 'Leuk voor feestjes en barbecues',
    price: '50,00 p/d'
  },
  {
    category: 'muziek',
    title: 'Rhaegar',
    description: 'Leuk voor honden feestjes en barbecues',
    price: 'onbetaalbaar'
  },
  {
    category: 'party-tent',
    title: '1km',
    description: 'Leuk voor grote feestjes en barbecues',
    price: '500,00 p/d'
  },
  {
    category: 'sta-tafel',
    title: 'Sta Tafel 40cm',
    description: 'Leuk voor feestjes en barbecues',
    price: '3,00 p/d'
  },
  {
    category: 'sta-tafel',
    title: 'Sta Tafel 80cm',
    description: 'Leuk voor feestjes en barbecues',
    price: '5,00 p/d'
  }
];
