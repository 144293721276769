<!-- Wrapper -->
<div id="wrapper">

  <!-- Main -->
  <div id="main">
    <div class="inner">

      <!-- Header -->
      <app-header></app-header>
      <div class="header-image"></div>

      <div class="content">
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>

  <!-- Sidebar -->
  <div id="sidebar">

    <div class="inner">

      <!-- Menu -->
      <app-page-menu></app-page-menu>

    </div>
  </div>

</div>
