<h1>Prijzen en producten</h1>
<table>
  <tr>
    <th>Product</th>
    <th>Prijs</th>
    <th>Borg</th>
  </tr>
  <tr>
    <td>Statafel wit/zwart</td>
    <td>&euro; 5,00</td>
    <td>&euro; 10,00</td>
  </tr>
  <tr>
    <td>Statafelrok wit/zwart</td>
    <td>&euro; 3,00</td>
    <td>&euro; 5,00</td>
  </tr>
  <tr>
    <td>Led strip op batterij voor onder de statafels</td>
    <td>&euro; 15,00</td>
    <td>&euro; 40,00</td>
  </tr>
  <tr>
    <td>JBL Part box 1000<br /><span class="description">De krachtigste Bluetooth speaker van dit moment!</span></td>
    <td>&euro; 90,00</td>
    <td>&euro; 350,00</td>
  </tr>
  <tr>
    <td>JBL Party box 310</td>
    <td>&euro; 50,00</td>
    <td>&euro; 195,00</td>
  </tr>
  <tr>
    <td>Fujifilm Instax Mini 11 (incl. 10 prints)</td>
    <td>&euro; 34,00</td>
    <td>&euro; 40,00</td>
  </tr>
  <tr>
    <td>10 prints voor Fujifilm Instax Mini 11</td>
    <td>&euro; 10,00</td>
  </tr>
  <tr>
    <td>Partytent<br /><span class="description">Grizzly easy up party tent 3m x 6m</span></td>
    <td>&euro; 50,00</td>
    <td>&euro; 100,00</td>
  </tr>
  <tr>
    <td>Levering spullen</td>
    <td colspan="2">&euro; 20,00 + &euro; 1,00 per km</td>
  </tr>
</table>
