<h1>Contact</h1>
<p>Bij vragen, reserveringen of al uw andere feest gerelateerde topics, neem gerust contact op.</p>
<table>
  <tr>
    <td>
      Telefonisch van 9:30 - 21:00<br />
    </td>
    <td><a href="tel:0638888091">0638888091</a></td>
  </tr>
  <tr>
    <td>Whatsapp</td>
    <td><a href="https://wa.me/31638888091">0638888091</a></td>
  </tr>
  <tr>
    <td>E-Mail</td>
    <td><a href="mailto:info@feestverhuurmolenlanden.nl">info@feestverhuurmolenlanden.nl</a></td>
  </tr>
</table>
