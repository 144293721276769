import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageMenuModule } from './page-menu/page-menu.module';
import { ProductModule } from './product/product.module';
import { CommonModule } from '@angular/common';
import { ContactModule } from './contact/contact.module';
import { DeliveryModule } from './delivery/delivery.module';
import { TermsModule } from './terms/terms.module';
import { HeaderModule } from './header/header.module';
import { PricesModule } from './prices/prices.module';
import { IndexModule } from './index/index.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,

    AppRoutingModule,

    ContactModule,
    DeliveryModule,
    HeaderModule,
    IndexModule,
    PageMenuModule,
    PricesModule,
    ProductModule,
    TermsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
